import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";

import Button from "../components/shared/Button";

import { useLanguageDetector } from "../hooks/useLanguageDetector";

import { Styles } from "./styles/heroSlider.js";

import Data from "../data/hero/hero-slider.json";
import DataRu from "../data/hero/hero-slider-ru.json";
import DataUz from "../data/hero/hero-slider-uz.json";

const HeroSlider = () => {
  const navigate = useNavigate();
  const [sliderData] = useLanguageDetector(Data, DataRu, DataUz);

  const settings = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: sliderData?.length > 1,
    speed: 3000,
    autoplay: sliderData?.length > 1 ? { 
      delay: 4000,
      disableOnInteraction: false,
    } : false,
    watchSlidesVisibility: false,
    effect: "fade",
    navigation: {
      prevEl: ".slider-button-prev",
      nextEl: ".slider-button-next",
    },
    renderPrevButton: () => (
      <div className="slider-button-prev swiper-btn">
        <i className="flaticon-arrow-left-th"></i>
      </div>
    ),
    renderNextButton: () => (
      <div className="slider-button-next swiper-btn">
        <i className="flaticon-arrow-right-th"></i>
      </div>
    ),
  };

  const handleNavigate = (path) => navigate(path);

  return (
    <Styles>
      {/* Hero Slider */}
      <section className="hero-slider-area">
        <Swiper {...settings}>
          {sliderData?.map((data, i) => (
            <div className="slider-item" key={i}>
              {data?.backgroundImagePosition ? (
                <>
                  <div className="image-container">
                    <div className="mask">
                      <img
                        className="slider-image"
                        src={`/assets/images/${data.backgroundImage}`}
                        alt="Pharmacetical Technical University"
                        loading="lazy"
                        style={{
                          objectPosition: data?.backgroundImagePosition,
                        }}
                      />
                    </div>
                  </div>
                  <div className="slider-table">
                    <div className="slider-tablecell">
                      <Container>
                        <Row>
                          <Col md="12">
                            <div className={data.uniqClass}>
                              <div className="slider-title">
                                <p>{data.title}</p>
                              </div>
                              <div className="slider-desc">
                                <h1>{data.desc}</h1>
                              </div>
                              <div className={data.btnClass}>
                                <Button
                                  text="Learn more"
                                  onClick={() =>
                                    handleNavigate(data.btnOneLink)
                                  }
                                />
                                <Button
                                  text="Contact Us"
                                  onClick={() =>
                                    handleNavigate(data.btnTwoLink)
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          ))}
        </Swiper>
      </section>
    </Styles>
  );
};

export default HeroSlider;
