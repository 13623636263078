import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/student-life";
import EnglishData from "../../data/student-life/student-life.json";
import RussianData from "../../data/student-life/student-life-ru.json";
import UzbekData from "../../data/student-life/student-life-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import HelmetComponent from "../../components/helmet/HelmetComponent";

const StudentLife = () => {
  const [studentLifeData] = useLanguageDetector(
    EnglishData,
    RussianData,
    UzbekData
  );

  return (
    <div className="main-wrapper student-life-page">
      <HelmetComponent title="Students" description={""} keywords={""} />
      <BreadcrumbBox
        title="Student Life"
        image="/assets/images/student-life-1.jpg"
      />
      <Styles>
        <section className="student-life">
          <Container>
            <Row>
              <Col xs="12">
                <div className="student-life-content">
                  {studentLifeData?.paragraphs?.map((par, index) => (
                    <p key={index} className="par">
                      {par}
                    </p>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default StudentLife;
