import React from "react";
import { useNavigate } from "react-router-dom";
import { Styles } from "./styles/olympiad.js";
import Button from "../../components/shared/Button.jsx";
import BreadcrumbBox from "../../components/common/Breadcrumb.jsx";
import { useTranslation } from "react-i18next";

const OlympiadPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Styles>
      <BreadcrumbBox
        title="LIFE SCIENCES OLYMPIAD 2025"
        image="/assets/images/banner-2.jpg"
        preTitle="Pharmaceutical Technical University announces"
        centerTitle={true}
        registerButton={true}
        registerLink="/olympiad/form"
      />
      <div className="main-wrapper olympiad-page">
        {/* Introduction Section */}
        <div className="olympiad-intro">
          <p>
            {t(
              "Being a knowledge competition Life Sciences Olympiad promotes learning biology and chemistry among youth and creates a platform for nurturing and recognizing talents and achievements school students in life sciences."
            )}
          </p>
          <p>
            {t(
              "Life Sciences Olympiad is a two-stage examination contest that evaluates your knowledge in biology and chemistry at the level of school educational and beyond."
            )}
          </p>
        </div>

        {/* Stages Section */}
        <div className="olympiad-stages">
          <h2>{t("Life Sciences Olympiad will take place in two stages:")}</h2>

          {/* Stage 1 */}
          <div className="stage">
            <h3>{t("Stage 1: Online exam competition.")}</h3>
            <p>
              {t(
                "In this stage participants are required to take an online examination in Uzbek, English or Russian language. The online examination link will be available at PTU web-site."
              )}
            </p>
            <p>
              <strong>{t("Dates:")}</strong> {t("21-23 February 2025")}
            </p>
            <p>
              {t(
                "This online phase will test participants' knowledge in life sciences. You can take an online exam any time during the abovementioned dates."
              )}
            </p>
          </div>

          {/* Stage 2 */}
          <div className="stage">
            <h3>{t("Stage 2: On-site exam competition.")}</h3>
            <p>
              {t(
                "Selected participants with higher marks will be invited to the 2nd stage of Life Sciences Olympiad."
              )}
            </p>
            <p>
              {t(
                "Selected participants will be invited to the University located in 'Tashkent Pharma Park' Innovative Research-Industrial Pharmaceutical Cluster."
              )}
            </p>
            <p>
              <strong>{t("Date:")}</strong> {t("16 March 2025")}
            </p>
            <p>
              <strong>{t("Language:")}</strong> {t("English")}
            </p>
          </div>
        </div>

        {/* Prizes Section */}
        <div className="olympiad-prizes">
          <h2>{t("Prizes & Scholarships:")}</h2>
          <p>
            {t(
              "Winners will receive scholarships for studying British higher educational programme at the Pharmaceutical Technical University, along with exciting prizes!"
            )}
          </p>
        </div>

        {/* Registration Section */}
        <div className="olympiad-registration">
          <h2>{t("Register and showcase your life sciences knowledge!")}</h2>
          <Button
            className="register-btn"
            onClick={() => navigate("/olympiad/form")}
            text={"Register"}
          />
          <p>
            {t(
              "Upon registration you will receive your unique ID code which is required in participating in Life Sciences Olympiad."
            )}
          </p>
        </div>
      </div>
    </Styles>
  );
};

export default OlympiadPage;
