import React from "react";
import CouresDetailsOverviewTab from "../../CourseDetailsOverviewTab";
import CourseDetailsStructureTab from "../../CourseDetailsStructureTab";
import CourseDetailsRequirementsTab from "../../CourseDetailsRequirementsTab";

export const UniversityProgrammeContent = ({ courseData }) => {
  return (
    <div className="course-tab-list">
      <CouresDetailsOverviewTab data={courseData?.overview} />
      <CourseDetailsStructureTab
        data={courseData?.structure}
        postTitle={courseData?.courseStructurePostTitle}
      />
      <CourseDetailsRequirementsTab
        key={courseData?.admissionRequirements}
        data={courseData?.admissionRequirements}
      />
    </div>
  );
};
