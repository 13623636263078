import React from "react";
import { Icon } from "@iconify/react";
import { colors } from "../../components/common/element/elements";
import Heading from "../../components/shared/Heading";
import {
  ScholarshipWrapper,
  ScholarshipSection,
  ScholarshipStep,
  ScholarshipLink,
  DatesList,
} from "./styles/style";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Container } from "react-bootstrap";

const scholarshipSteps = [
  {
    title: "Apply for Scholarship",
    description:
      "State that you need a scholarship ticking in the box during online application process. After this, the University will add to scholarship applicants list and start communicating with you on scholarship arrangements.",
  },
  {
    title: "Take Scholarship Exam",
    description:
      "The scholarship exam consists of questions and tasks on chemistry and biology. The examination will be written and in English. Applicants who obtain outstanding marks will be granted PIDA scholarship.",
  },
  {
    title: "Alternative: Join Life Sciences Olympiad",
    description: (
      <>
        Winners of Life Sciences Olympiad will be granted PIDA scholarship and
        exciting prizes. For more information on Life Sciences Olympiad{" "}
        <ScholarshipLink href="/olympiad">visit here</ScholarshipLink>
      </>
    ),
  },
  {
    title: "Sign Agreement",
    description:
      "Sign a scholarship agreement after meeting the academic requirements for entry.",
  }
];

const ScholarshipPage = () => {
  return (
    <>
      <Breadcrumb
        title="PIDA Scholarships"
        previousPath="/"
        image={"/assets/images/scholarship.jpg"}
        // previousTitle="Home"
      />
      <Container>
        <ScholarshipWrapper id="scholarship">
          <ScholarshipSection>
        <p>
          Every year to support talented youth in their higher learning
          Pharmaceutical Industry Development Agency grants 40 SCHOLARSHIPS for
          applicants who is able to demonstrate outstanding knowledge and learning
          skills in life sciences.
        </p>
        <p>
          The scholarship covers the tuition fee for the whole period of studies in
          an undergraduate programme at PTU.
        </p>
        <p className="fw-bold">
          Scholarships are granted on a competitive basis.
        </p>
      </ScholarshipSection>

      <ScholarshipSection>
        <Heading tag="h5" title="Who is eligible for a scholarship" className="mb-3" />
        <p>
          Only local applicants can get scholarships to study in an undergraduate
          programme in full-time mode at PTU.
        </p>
      </ScholarshipSection>

      <ScholarshipSection>
        <Heading tag="h5" title="How to get a scholarship" className="mb-3"  />
        <p>Applicants looking for a scholarship need to do the following:</p>

        <div>
          {scholarshipSteps.map((step, index) => (
            <ScholarshipStep key={index}>
              <div className="d-flex">
                <div className="icon-wrapper">
                  <Icon
                    icon="ph:graduation-cap-duotone"
                    fontSize={24}
                    color={colors.bg1}
                  />
                </div>
                <div>
                  <h6>{step.title}</h6>
                  <p>{step.description}</p>
                </div>
              </div>
            </ScholarshipStep>
          ))}
        </div>
      </ScholarshipSection>

      <ScholarshipSection>
        <Heading tag="h5" title="Scholarship Dates" className="mb-3" />
        <DatesList>
          <li>Deadline for scholarship applications – [Date]</li>
          <li>Date of scholarship exam - [Date]</li>
        </DatesList>
      </ScholarshipSection>

      <ScholarshipSection>
        <Heading tag="h5" title="Sample scholarship exam" className="mb-4" />
        
        <div className="sample-item">
          <h6 className="sample-title">Scholarship Exam Sample (2025)</h6>
          <a href="https://drive.google.com/file/d/1C61_bPlNweNX9Zd7lppsadJ3f_VlrQJD/view" target="_blank" className="download-button">
            View
          </a>
      </div>
      <div className="sample-item">
          <h6 className="sample-title">Scholarship Exam Sample (2025) - Answers</h6>
          <a href="https://drive.google.com/file/d/1hz9oY_FwJRcGt6x6nIrc5CyTAwTjANca/view" target="_blank" className="download-button">
            View
          </a>
      </div>
      <div className="sample-item">
        <h6 className="sample-title">Scholarship Exam Demo Version</h6>
        <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=X3aHJ0jy4kCTBf-9vu4HY1Fv0usvgehGu3HyRhs032JUMTVMUzdNT1U0RzRGRFhaNVFSM0hIODI1Vy4u" target="_blank" className="download-button">
          View
        </a>
      </div>

      </ScholarshipSection>
    </ScholarshipWrapper>
      </Container>
    </>
  );
};

export default ScholarshipPage;