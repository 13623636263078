import React from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { colors } from "../../../components/common/element/elements";
import Heading from "../../../components/shared/Heading";

const CourseDetailsRequirementsTab = ({ data }) => {
  console.log(data)

  return (
    <section className="overview-tab" id="requirements">
      {data?.requirementsList?.length > 0 && (
        <>
          <Heading tag="h5" title={data?.title} />
          <p className="mb-3">{data?.description}</p>
          {data?.requirementsList?.map((requirement, index) => (
            <div className="requirements-item" key={index}>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="course-desc-container d-flex align-items-center"
              >
                <div>
                  <Icon
                    icon="ph:paper-plane-right-duotone"
                    fontSize={24}
                    className="pt-1"
                    color={colors.bg1}
                  />
                </div>
                <div className="course-desc-content d-flex flex-column">
                  <h6>{requirement?.title}</h6>
                  <p>{requirement?.description}</p>
                </div>
              </motion.div>
            </div>
          ))}
        </>
      )}

      <div className="how-to-apply">
        <Heading tag="h5" title={data?.howToApply?.title} />
        <div>
          <p>
            {data?.howToApply?.description?.text}{" "}
            <a
              href={data?.howToApply?.description?.link?.url}
              target="_blank"
              rel="noopener noreferrer"
              className="how-to-apply-link"
            >
              {data?.howToApply?.description?.link?.text}
            </a>
          </p>
        </div>
        <div className="d-flex how-to-apply-list">
          <p>
            {data?.howToApply?.description2?.text1}
            <a
              href={data?.howToApply?.description2?.link1}
              target="_blank"
              rel="noopener noreferrer"
              className="how-to-apply-link"
            >
              {data?.howToApply?.description2?.link1}
            </a>
            {data?.howToApply?.description2?.text2}
            <a
              href={data?.howToApply?.description2?.link2}
              target="_blank"
              rel="noopener noreferrer"
              className="how-to-apply-link"
            >
              {data?.howToApply?.description2?.link2}
            </a>
            {data?.howToApply?.description2?.text3}
          </p>
        </div>
      </div>

      <div className="tuition-fee">
        <Heading tag="h5" title="Tuition Fee" />
        <div>
          {data?.tuitionFee?.map((item, index) => (
            <p key={index} className="mb-2">
              {Array.isArray(item?.text)
                ? item?.text?.map((text, index) => (
                    <span key={index}>
                      {text?.text}
                      {text?.strong && (
                        <strong className="mx-2">{text?.text}</strong>
                      )}
                    </span>
                  ))
                : item?.text}
              {item?.link && (
                <a
                  href={item?.link?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tuition-fee-link"
                >
                  {item?.link?.text}
                </a>
              )}
            </p>
          ))}
        </div>
      </div>

      <div className="facilities">
        <Heading tag="h5" title={data?.facilities?.title} />
        <div className="facilities-list">
          {data?.facilities?.list?.map((item) => {
            return (
              <div className="facilities-card">
                <div className={`image-wrapper ${item?.color}`}>
                  <img
                    src={`/assets/images/programme-details/facilities/${item?.image}`}
                    alt={item?.title}
                    width={"100%"}
                    height={200}
                  />
                </div>
                <div className="facilities-card-content">
                  <h6>{item?.title}</h6>
                  <p>{item?.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CourseDetailsRequirementsTab;
